<template>
  <div id="movie_video"></div>
</template>

<script>
export default {
  watch: {
    route(val) {
      if (!this.showAd) this.startAd()
    }
  },
  computed: {
    route() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      vid: '759a4f849ac8cad9fefcb3ce082f3c60',
      showAd: false,
    }
  },
  mounted() {
    if (!this.$device.isDesktop) {
      setTimeout(() => {
        this.startAd()
      }, 10 * 1000)
    } else {
      this.startAd()
    }
  },
  methods: {
    startAd() {
      if (this.showAd) return
      this.showAd = true
      const script = document.createElement('script')
      script.src = `https://vak345.com/s.js?v=${this.vid}`
      script.setAttribute('async', 'true')
      document.body.appendChild(script)

      if (!this.$device.isDesktop) {

        const mobileElem = document.querySelector('.mobile')
        const menuElem = document.querySelector('.mobile .mainmenu .profile')
        const chNavElem = document.querySelector('.mobile .ch-nav')

        setInterval(() => {
          let findBlock = 0
          document.body.querySelectorAll('div').forEach(el => {
            if (el.style.zIndex === '2147483647') {
              // console.log(el.children)

              findBlock = 1

              if (menuElem && mobileElem) {
                if (el.children[1] && el.children[1].style.opacity === '1') {
                  // console.log(el.children[1].style.opacity)

                  menuElem.style.bottom = 31 + 'vh'
                  mobileElem.style.paddingBottom = 31 + 'vh'
                  
                } else {
                  menuElem.style.bottom = 0 + 'px'
                  mobileElem.style.paddingBottom = 0 + 'px'
                }
              }

              if (chNavElem) {
                if (el.children[1] && el.children[1].style.opacity === '1') {
                  // console.log(el.children[1].style.opacity)

                  chNavElem.style.bottom = 31 + 'vh'
                  
                } else {
                  chNavElem.style.bottom = 0 + 'px'
                }
              }
            }
          })

          if (!findBlock) {
            if (menuElem && mobileElem) {
              menuElem.style.bottom = 0 + 'px'
              mobileElem.style.paddingBottom = 0 + 'px'
            }

            if (chNavElem) {
              chNavElem.style.bottom = 0 + 'px'
            }
          }
        }, 1000)
      }
    }
  }
}
</script>
